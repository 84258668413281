/* You can add global styles to this file, and also import other style files */

// Path prefixes for SDX assets (relative inside the package)
// $font-path: "../../dist/fonts/";
// $image-path: "../../../dist/images/";

// @import "@swisscom/sdx/stylesheets/sdx";

@use "./_variables.scss" as v;
@use "./kendo-overrides.scss";

.align-items-center {
  align-items: center;
}

.text-align-center {
  text-align: center;
}

.flex-container {
  flex-wrap: nowrap;
  // TODO: Mobile Breakpoint is not optimised
  display: flex;
  align-items: stretch;
  justify-content: center;
  align-content: normal;
}

.flex-container-items-baseline {
  display: flex;
  align-items: baseline;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-item-size-1 {
  flex-basis: 33.33%;
}

.flex-item-size-3 {
  flex-basis: 66.66%;
}

.flex-container-center {
  flex-wrap: wrap;
  display: flex;
  align-content: center;
  justify-content: center;
}

.flex-container-vertical-center {
  display: flex;
  align-items: center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-container-item {
  flex: 1 1 auto;
}

.no-wrap-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.shell-message {
  border: 2px solid orange;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: lightyellow;
}

// Loading Indicator

.flex-loading-container {
  flex-wrap: wrap;
  display: flex;
  align-items: stretch;
  align-content: center;
  justify-content: center;

  &.full-size {
    ///TODO: Find a better way to create the layout.
    // ISSUE: The content of a page is flexbox (row) and starts always from the top (flex-start) by the class ".flex-container".
    //        Find a way to center the loading indicator on the page without specifying the height of the item inside the flexbox (do not breaking the start-page)
    height: calc(100vh - 160px);
    width: 100vw;
  }

  &.fixed-size {
    height: 100%;
    width: 100%;
  }
}

// Disable Input
//TODO: Add all input-components from kendo to apply css if they are set to readonly
input[readonly],
kendo-dropdownlist[readonly],
kendo-datepicker:has(kendo-dateinput):has(input[readonly]) {
  opacity: 0.4 !important;
  cursor: not-allowed;
}


.btn-primary {
  border: 1px solid v.$sdx-int-blue;
  border-radius: v.$border-radius;
  display: inline-flex;
  overflow: hidden;
  // TODO: create parameter for component so height is variable
  max-height: 100%;

  a {
    text-decoration: none;
  }

  .btn-animated-primary {
    padding: 7px 10px 7px 10px;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    color: v.$sdx-white;
    background-color: v.$sdx-int-blue;

    .btn-text {
      margin-left: 5px;
    }
  }

  .btn-animated-primary:hover {
    background-color: v.$sdx-int-blue--active;
    color: v.$sdx-white;
    transition: all, 1s, color, 0.1s;
    cursor: pointer;
  }
}



.btn-secondary {
  border: 1px solid v.$sdx-aluminium;
  border-radius: v.$border-radius;
  display: inline-flex;
  overflow: hidden;
  // TODO: create parameter for component so height is variable
  max-height: 100%;

  &.disabled {
    outline: none;
    cursor: default;
    opacity: 0.65;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
    pointer-events: none;
  }

  a {
    text-decoration: none;
  }

  .btn-animated-secondary {
    padding: 1px 10px 1px 10px;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    color: v.$sdx-gray;

    .btn-text {
      margin-left: 5px;
    }
  }

  .btn-animated-secondary:hover {
    background-color: v.$sdx-int-blue-tint-3;
    color: v.$sdx-int-blue--active;
    transition: all, 1s, color, 0.1s;
    cursor: pointer;
  }
}
