@use "./kendo/navigation.scss";
@use "./kendo/panelbar.scss";
@use "./kendo/inputs.scss";
@use "./kendo/stepper.scss";
@use "./kendo/icons.scss";
@use "./kendo/card.scss";
@use "./kendo/menu.scss";
@use "./kendo/button.scss";
@use "./kendo/tooltip.scss";
@use "./kendo/pager.scss";
@use "./kendo/grid.scss";
@use "./kendo/tabstrip.scss";
@use "./kendo/dialog.scss";
@use "./kendo/badge.scss";

.k-grid-columnmenu-popup {
  width: 100%;
}

.k-notification-group {
  // Use !important because Kendo applies the styles for the group directly on the HTML Element and not in a class. We need to overwrite them.
  right: 10px !important;
  bottom: 85px !important;
}

.k-filtercell-operator {
  display: contents;
}

.k-badge-inside {
  z-index: 100;
}
