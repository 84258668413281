@use "./../_variables.scss" as v;


.k-menu:not(.k-context-menu) {
  background-color: v.$sdx-white;
}

.k-menu.k-menu-vertical:not(.k-context-menu) {
  padding: 0;
}

.active-link {

  >.k-link {
    color: v.$sdx-int-blue--active;
  }

  color: v.$sdx-int-blue--active;
  background-color: v.$sdx-int-blue-tint-3;
}

.k-menu-link {
  color: v.$sdx-gray;
}

.k-menu-link:hover {
  color: v.$sdx-int-blue--active;
}

.k-menu-vertical>.k-menu-item>.k-menu-link {
  padding-right: 0px;
}

@media (min-width: 900px) {
  .kendomenu>.k-menu-horizontal {
    flex-direction: column !important;
  }
}

aog-home-navigation {
  .k-menu {
    flex-wrap: wrap;
    display: flex;
    align-content: center;
    justify-content: center;

    &:not(.k-context-menu)>.k-item:focus {
      box-shadow: unset;
    }
  }
}
