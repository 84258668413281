@import "./common.scss";

.k-stepper {
	font-size: 17px;

	&>.k-progressbar {
		height: 8px;
		top: 24px;

		.k-state-selected {
			background: linear-gradient(to right, $sdx-int-blue, $sdx-int-blue--active);
		}
	}

	.k-step-done {
		.k-step-indicator {
			border-color: $sdx-int-blue--active;
			color: $sdx-white;
			background-color: $sdx-int-blue--active;
		}
	}

	.k-step-current {
		.k-step-indicator {
			border-color: $sdx-int-blue--active;
			color: $sdx-white;
			background-color: $sdx-int-blue--active;
		}

		.k-step-label {
			font-weight: bold;
			color: $sdx-navy;
		}
	}

	.k-step-indicator {
		border-color: $sdx-gray-tint-8;
		color: $sdx-gray;
		background-color: $sdx-white;
		border-radius: 50%;
		margin: 3px;
		width: 45px;
		height: 45px;
		border-width: 1px;
		border-style: solid;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;
		overflow: visible;
		transition-property: color, background-color, border-color;
		transition-duration: 0.4s;
		transition-timing-function: ease-in-out;
		box-sizing: content-box;
	}

	.k-icon {
		font-size: 22px;
	}
}

.k-step-list-horizontal {
	.k-step-link {
		margin: auto;
		max-width: 20em;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.k-step-label {
		text-align: center;
		color: $sdx-navy;
	}
}

.k-stepper .k-step-done:hover .k-step-indicator,
.k-stepper .k-step-done.k-step-hover .k-step-indicator {
	background-color: $sdx-int-red;
}

.k-stepper .k-step-current:hover .k-step-indicator,
.k-stepper .k-step-current.k-step-hover .k-step-indicator {
	background-color: $sdx-int-red;
}
