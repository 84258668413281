@use "../../styles/variables" as v;

.k-grid .k-table-row, .k-grid .k-table-alt-row{
    border-color: transparent;
    background-color: v.$sdx-white;
    font-family: TheSans;
}

.k-grid td, .k-grid .k-table-td, .k-grid .k-grid-content, .k-grid .k-grid-header-locked, .k-grid .k-grid-content-locked {
    border-color: transparent;
    border-bottom: 1px solid v.$sdx-gray-tint-9;
    padding-top: 10px;
    padding-bottom: 7px;
}

.k-filter-row>td, .k-filter-row>th, .k-grid td, .k-grid-content-locked, .k-grid-footer, .k-grid-footer-locked, .k-grid-footer-wrap, .k-grid-header, .k-grid-header-locked, .k-grid-header-wrap, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-header {
    border-color: rgba(0,0,0,.08);
}

.k-grid > .k-grid-pager > .k-pager-sizes > .k-picker{
    min-height: 11px;
    min-width: 10px;
    border-color: v.$sdx-gray-tint-8;
  }

.k-grid .k-grid-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.k-grid .k-grid-header .k-grid-filter-menu{
  .k-icon-xl{
      font-size: 18px;
  }
}

.k-grid .k-grid-header .k-grid-filter-menu:not(.k-active){
  color: v.$sdx-int-blue;
}

.k-grid-filter-popup .k-filter-menu-container{
  .k-dropdownlist, .k-datepicker{
      min-height: 18px;
      .k-input-inner{
          .k-input-value-text{
              font-size: 1rem;
          }
      }
  }
  .k-actions .k-button{
      padding-block: 0.2rem;
      font-size: 1rem;
  }
  .k-input{
      min-height: 18px;
      font-size: 1rem;
  }
}


.k-grid tbody tr:hover {
  transition: all, 1s, color, 0.1s;
  background-color: v.$sdx-int-blue-tint-3 !important;
}

.k-grid .k-master-row .k-table-row {
  border-radius: v.$border-radius;
}

.k-grid {
  border-radius: v.$border-radius;
  overflow: hidden;
}
