@import "./common.scss";

.k-panelbar {
  > .k-item {
    > .k-link {
      color: $sdx-int-blue;
      background-color: $sdx-white;

      .k-icon {
        color: $sdx-int-blue--active;
      }
    }

    > .k-link.k-state-selected:hover,
    > .k-link.k-state-selected.k-state-hover,
    > .k-link.k-state-selected:focus,
    > .k-link.k-state-selected.k-state-focus,
    > .k-link.k-state-selected.k-state-focused,
    > .k-link.k-state-selected:hover:focus,
    > .k-link.k-state-selected.k-state-hover.k-state-focus,
    > .k-link.k-state-selected.k-state-hover.k-state-focused,
    > .k-link.k-state-selected {
      color: $sdx-white;
      background-color: $sdx-int-blue;

      .k-icon {
        color: $sdx-white;
      }
    }
  }
}
