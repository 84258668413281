@use "@swisscom/sdx/stylesheets/sdx/variables" as sdx;

// colors
$sdx-gray: #333333;
$sdx-gray-tint-2: #474747;
$sdx-gray-tint-3: #5c5c5c;
$sdx-gray-tint-4: #666666;
$sdx-gray-tint-5: #858585;
$sdx-gray-tint-6: #adadad;
$sdx-gray-tint-7: #bbbbbb;
$sdx-gray-tint-8: #d6d6d6;
$sdx-gray-tint-9: #dee2e6;
$sdx-gray-tint-10: #f8f9fa;

$sdx-navy: #001155;
$sdx-aluminium: #dde3e7;
$sdx-aluminum-tint-2: #e4e9ec;
$sdx-horizon: #eef3f6;
$sdx-horizon-tint-2: #f8fafb;
$sdx-white: #ffffff;

$sdx-blue: #11AAFF;
$sdx-int-blue: #086ADB;
$sdx-int-blue--active: #0048CF;
$sdx-int-blue-tint-1: desaturate(lighten($sdx-int-blue, 10), 2);
$sdx-int-blue-tint-2: desaturate(lighten($sdx-int-blue, 25), 5);
$sdx-int-blue-tint-3: desaturate(lighten($sdx-int-blue, 48), 10);

$sdx-int-green: #1B8712;
$sdx-int-green--active: #0D6F2C;
$sdx-int-green-tint-1: desaturate(lighten($sdx-int-green, 10), 2);
$sdx-int-green-tint-2: desaturate(lighten($sdx-int-green, 20), 5);
$sdx-int-green-tint-3: desaturate(lighten($sdx-int-green, 55), 10);

$sdx-int-red: #dd1122;
$sdx-int-red--active: #be0000;
$sdx-int-red-tint-1: desaturate(lighten($sdx-int-red, 10), 2);
$sdx-int-red-tint-2: desaturate(lighten($sdx-int-red, 20), 5);
$sdx-int-red-tint-3: desaturate(lighten($sdx-int-red, 50), 10);

$sdx-int-orange: #CF4A0C;
$sdx-int-orange--active: #BA3E06;
$sdx-int-orange-tint-1: desaturate(lighten($sdx-int-orange, 5), 0);
$sdx-int-orange-tint-2: desaturate(lighten($sdx-int-orange, 15), 2);
$sdx-int-orange-tint-3: desaturate(lighten($sdx-int-orange, 38), 5);

$sdx-acc-turquoise: #0EABA9;
$sdx-acc-turquoise-rollover: #0C847E;
$sdx-acc-azure: #1781e3;
$sdx-acc-azure-rollover: #0851da;
$sdx-acc-iris: #5944C6;
$sdx-acc-iris-rollover: #42389E;
$sdx-acc-orchid: #A63297;
$sdx-acc-orchid-rollover: #7F2879;
$sdx-acc-pink: #E61E64;
$sdx-acc-pink-rollover: #BF1B5A;

$link-hover-text: $sdx-int-blue;

$text-primary: sdx.$color-sc-navy;
$text-secondary: sdx.$color-gray-tint-6;

// numbers
$navmenu-height: 40px;
$navmenu-font-size: 14px;
$modal-header-height: 48px;
$grid-height: 88vh;
$footer-text-height: 24px;
$footer-height: sdx.$footer-compact-height;
$header-height: sdx.$navigation-level1-height;

// border
$border-radius: sdx.$border-radius;

// spinner
$sdx-spinner-color: sdx.$color-navy;

// z-index
$sdx-zindex-navigation: sdx.$zindex-navigation;
$sdx-zindex-modal: sdx.$zindex-modal;
