@import "./common.scss";

.k-button-link-base:hover,
.k-button-link-base.k-hover {
    color: $sdx-int-blue;
}

.k-button-link {

    .k-button-text {
        color: $sdx-int-blue;
    }

    .k-button-text:hover {
        color: $sdx-int-blue--active;
    }
}

.k-button-link:hover {
    text-decoration: none;
}

.k-button-icon {
    color: $sdx-int-blue;
}

.k-button-outline-base {
    border: 1px solid $sdx-aluminium;

    .k-button-icon {
        color: $sdx-gray;
    }

    &:hover {
        background-color: $sdx-int-blue-tint-3;
        color: $sdx-int-blue--active;
        border-color: $sdx-aluminium;
    }
}
