@import "./common.scss";

.k-badge {
    // Badge has default z-index 9999 from kendo itself. It is shown over the dropdown and tooltip content which is wrong.
    // Set the z-index to 0 as the badge is nowhere overlapping a different object
    z-index: 0;
}

.k-badge-solid-warning {
    background-color: $sdx-int-orange;
    color: $sdx-white;
    border-color: $sdx-int-orange;
}
