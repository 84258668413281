@use "../../styles/variables" as v;

.k-pager{
    border-style: none !important;
}

.k-pager-page-sizes{
    .k-picker{
        min-height: 18px;
        border-color: v.$sdx-gray-tint-8;
    }
    .k-input-inner{
        padding: 4px 8px;
    }
}

.k-datapager-input{
    .k-input{
        min-height: 18px;
        border-color: v.$sdx-gray-tint-8;
    }
    .k-input-inner{
        padding: 4px 8px;
    }
}
