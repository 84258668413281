@use "./../_variables.scss" as v;

.k-tooltip {
    background-color: v.$sdx-white;
    color: v.$sdx-gray;
    box-shadow: rgb(0 0 0 / 50%);
    padding: 10px;
    border: 1px solid v.$sdx-aluminium;

    .k-callout {
        background-color: transparent;
        color: v.$sdx-white;
    }

}

.k-tooltip-content {
    max-width: 300px;
    word-wrap: break-word;
    height: max-content;
    white-space: pre-line;
}