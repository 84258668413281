@import "./common.scss";

// Input

.k-input,
.k-picker {
    color: $sdx-gray;
    background-color: $sdx-white;
    border: 1px solid $sdx-gray-tint-4;
    border-radius: 5px;
    min-height: 48px;
    letter-spacing: -0.1px;
    font-style: inherit;
    font-variant: inherit;
    font-stretch: inherit;
    line-height: inherit;
    font-family: inherit;
    font-size: 18px;
    font-weight: 300;
    outline: none;
    width: 100%;
    user-select: text;
    appearance: none;
    display: flex;
    align-items: center;
    caret-color: $sdx-int-blue;
    cursor: inherit;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

// Disable box-shadow for all input components
.k-input:focus,
.k-input.k-focus,
.k-input:focus-within,
.k-checkbox:focus,
.k-picker:focus-within {
    box-shadow: none !important;
    border-color: $sdx-int-blue;
}

// Labels

.k-form {
    legend {
        color: $sdx-gray !important;
    }
    .k-label {
        margin-bottom: 4px;
        font-size: 16px;
    }
    kendo-label {
        margin-bottom: 0;
    }
    .k-form-legend {
        text-transform: unset;
        border: unset;
        margin: 0;
    }
}

// Set label for dropdown correctly
kendo-label > .k-label {
    margin: unset;
    margin-bottom: 4px;
}

// Set label for checkbox correctly
.k-checkbox-label > label {
    margin-bottom: 0 !important;
    padding-left: 16px;
    font-size: 18px !important;
    color: $sdx-gray;
}

// DatePicker

kendo-datepicker .k-button {
    border-style: none;
}

.k-input-md .k-input-button {
    padding: 0.375rem 0.75rem;
    background-color: $sdx-white;
}

// Dropdown

kendo-dropdownlist .k-input-inner {
    background: $sdx-white;
}

.k-picker:hover {
    background-color: $sdx-white;
    border-color: $sdx-gray-tint-4;
}

.k-picker .k-input-button {
    background: $sdx-white;
}

// Numeric textbox

kendo-numerictextbox {
    .k-input-inner {
        background: $sdx-white;
    }

    .k-input-spinner:hover {
        background-color: $sdx-white;
        border-color: $sdx-gray-tint-4;
    }

    .k-icon-button {
        background: $sdx-white;
    }
}

// Textarea

textarea.k-input-inner {
    min-height: 48px;
    max-height: 264px;
}

// Checkbox & Radio

.k-checkbox {
    border-color: $sdx-gray-tint-4;
}

.k-checkbox:checked {
    // Get the svg code from /node_modules/@swisscom/sdx/dist/icons/011-check-mark.svg
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M23.49 6.128a.997.997 0 0 0-1.361.382l-8.354 14.85-6.068-6.067a1 1 0 1 0-1.414 1.414l7 7a1.002 1.002 0 0 0 1.579-.217l9-16a1 1 0 0 0-.382-1.362z' fill='%23086ADB'/%3E%3C/svg%3E%0A");
    background-color: $sdx-white;
    border-color: $sdx-int-blue;
}

.k-radio:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23086ADB'/%3e%3c/svg%3e");
    border-color: $sdx-int-blue;
    color: $sdx-int-blue;
    background-color: $sdx-white;
}

.k-checkbox-md,
.k-radio-md {
    width: 22px;
    height: 22px;
}

.k-filtercell{
    .k-input{
        min-height: 18px;
        border-color: $sdx-gray-tint-8;
    }
    .k-filtercell-wrapper > .k-filtercell-operator > .k-dropdown-operator{
        min-height: 18px;
        border-color: $sdx-gray-tint-8;
    }
    .k-button-icon{
       font-size: 18px;
    }
}

.k-input-loading-icon  {
    /* Adjust to match color and stroke-width from SDX webcomponent SVG */
    color: $sdx-spinner-color;
    font-size: 24px;
}