@import "./common.scss";

// .k-i-none {
//     display: none;
// }

.k-i-calendar::before {
    content: "\E009";
    font-family: "sdx-icons";
    color: $sdx-int-blue--active;
    font-size: 27px;
}

.k-i-arrow-s::before {
    content: "\E00D";
    font-family: "sdx-icons";
    color: $sdx-int-blue--active;
}

.k-i-arrow-n::before {
    content: "\E010";
    font-family: "sdx-icons";
    color: $sdx-int-blue--active;
}

.k-i-delete::before {
    content: "\E008";
    font-family: "sdx-icons";
    color: $sdx-int-blue--active;
}

.k-i-edit::before {
    content: "\E019";
    font-family: "sdx-icons";
    color: $sdx-int-blue--active;
}

.k-i-check::before {
    content: "\E00B";
    font-family: "sdx-icons";
    color: $sdx-int-blue--active;
}

.k-i-cancel::before {
    content: "\E00A";
    font-family: "sdx-icons";
    color: $sdx-int-blue--active;
}

.k-i-warning::before {
    content: "\E05E";
    font-family: "sdx-icons";
}

.k-i-more-horizontal::before {
    content: "\e035";
    font-family: "sdx-icons";
    color: $sdx-int-blue--active;
}

.k-i-plus-outline::before {
    content: "\e03d";
    font-family: "sdx-icons";
    color: $sdx-int-blue--active;
}

.k-i-preview::before {
    content: "\e0d9";
    font-family: "sdx-icons";
    color: $sdx-int-blue--active;
}

.k-i-page-properties::before {
    content: "\e0d5";
    font-family: "sdx-icons";
}

.k-i-lock::before {
    content: "\e031";
    font-family: "sdx-icons";
}

.k-i-crowd::before {
    content: "\e159";
    font-family: "sdx-icons";
}

.k-i-share::before {
    content: "\e0c5";
    font-family: "sdx-icons";
}

.k-i-user::before {
    content: "\e03a";
    font-family: "sdx-icons";
}

.k-i-filter::before {
    content: "\e0fd";
    font-family: "sdx-icons";
}

.k-i-check-circle::before {
    content: "\e00c";
    font-family: "sdx-icons";
}
