@import "./common.scss";

.k-breadcrumb-root-link {
  color: $sdx-int-blue;
  padding: 0px 0px;
  margin-right: 0px;
}

.k-breadcrumb-link {
  color: $sdx-int-blue;
  padding: 0px 0px;
}

.k-breadcrumb-last-item {
  color: $sdx-gray-tint-6;
  padding: 0px 0px;
}
