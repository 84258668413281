@import "./common.scss";

.k-tabstrip-items-wrapper {

    //  SDX colors
    background-color: $sdx-horizon;

    .k-item {
        color: $sdx-gray;
    }

    .k-item.k-active {
        color: $sdx-int-blue;
    }

    // Remove ugly focus effect
    .k-item:focus,
    .k-item.k-focus {
        box-shadow: none;
    }
}

.k-tabstrip-content, .k-tabstrip > .k-content{
  border-style: none;
}
